import { Dispatch, useCallback, useState } from "react";
import useSearch from "src/utils/hooks/useSearch";
import { useFeature } from "src/feature/useFeature";
import { navigateToNewState } from "src/utils/location/navigateToNewState";
import { useNavigate } from "react-router";
import { useTypedLocation } from "src/utils/hooks/useTypedLocation";
import { Action } from "../TripPlannerProvider";
import { createTripPlanFromSearchResponse } from "../util/createTripPlanFromSearchResponse";
import { useTripURLGeocodedPlaces } from "./useTripURL";

export function useInitialTripFromSearch(dispatch: Dispatch<Action>) {
  const [hasUsedSearch, setHasUsedSearch] = useState(false);
  const { searchResponse } = useSearch();
  const isTripURLFeature = useFeature("TripURL");
  const { places, tripIdData } = useTripURLGeocodedPlaces();
  const navigate = useNavigate();
  const location = useTypedLocation();

  const addTripFromSearch = useCallback(() => {
    if (searchResponse && !hasUsedSearch) {
      const tripFromSearch = createTripPlanFromSearchResponse(searchResponse);
      dispatch({
        type: "PASSIVE_UPDATE",
        trip: tripFromSearch,
      });
      setHasUsedSearch(true);
    }
  }, [searchResponse, dispatch, hasUsedSearch]);

  const addTripFromURL = useCallback(() => {
    if (places.length && !hasUsedSearch) {
      let tripDataWithDefaults = {
        transport: {},
      };
      if (tripIdData) {
        tripDataWithDefaults = tripIdData;
      }
      dispatch({
        type: "PASSIVE_UPDATE",
        trip: {
          ...tripDataWithDefaults,
          places,
        },
      });
      setHasUsedSearch(true);
      // Replaces browser history with new tripPlannerDetails on first load
      navigateToNewState(
        navigate,
        {
          ...location.state,
          tripPlannerDetails: {
            ...tripDataWithDefaults,
            places,
          },
        },
        location,
        true
      );
    }
  }, [places, hasUsedSearch, tripIdData, dispatch, navigate, location]);

  const addTripFn = isTripURLFeature ? addTripFromURL : addTripFromSearch;

  return {
    addTripFromSearch: addTripFn,
    hasUsedSearch,
  };
}
