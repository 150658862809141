// This is a record of all points that generate hotjar logs and recordings
// We currently have a limit of 100 hotjar recording per day.
// Many of these recordings are used up in areas which no longer require it.
// This config aims to better manage that limited amount of recordings.

import { Screen } from "src/analytics/generateScreenComponentsRendered/Screen";
import { ONE_DAY_IN_MILLISECONDS } from "./conversions/time";

const DEFAULT_VALID_DURATION_DAYS = 30;

type hotjarEvents =
  | "RecordSession"
  | "RecordAccomSession"
  | "ReturnTripEligible"
  | "Understand_ScheduleDetailScreen"
  | "TripPlannerScreen";

export type HotjarPoint = {
  eventName: hotjarEvents;
  renewedOn: Date;
  screen: Screen;
  chance?: number;
  isPersisting?: boolean;
};

export type HotjarOrigin =
  | "ticketPicker"
  | "scheduleDetailScreen"
  | "hotelsScreen"
  | "dynamicSchedules"
  | "segmentHeader"
  | "datePicker"
  | "segmentScreen"
  | "tripDynamicSchedules"
  | "tripSegmentHeader"
  | "tripPlannerInteraction"
  | "tripPlannerRecommendations";

export type HotjarConfig = Record<HotjarOrigin, HotjarPoint>;

export const hotjarConfig: HotjarConfig = {
  ticketPicker: {
    eventName: "RecordSession",
    renewedOn: new Date("2021-11-12"),
    screen: "Segment",
  },
  segmentScreen: {
    eventName: "ReturnTripEligible",
    renewedOn: new Date("2023-02-24"),
    screen: "Segment",
  },
  hotelsScreen: {
    eventName: "RecordAccomSession",
    renewedOn: new Date("2023-03-21"),
    screen: "HotelPane",
    chance: 0.001,
  },
  tripDynamicSchedules: {
    eventName: "RecordSession",
    renewedOn: new Date("2023-05-29"),
    screen: "TripPlanner",
  },
  tripSegmentHeader: {
    eventName: "RecordSession",
    renewedOn: new Date("2023-05-29"),
    screen: "TripPlanner",
  },
  scheduleDetailScreen: {
    eventName: "Understand_ScheduleDetailScreen",
    renewedOn: new Date("2023-06-01"),
    screen: "Segment",
  },
  tripPlannerInteraction: {
    eventName: "TripPlannerScreen",
    renewedOn: new Date("2024-04-17"),
    screen: "TripPlanner",
    chance: 0.5,
  },
  dynamicSchedules: {
    eventName: "RecordSession",
    renewedOn: new Date("2023-09-06"),
    screen: "Segment",
  },
  datePicker: {
    eventName: "RecordSession",
    renewedOn: new Date("2023-10-23"),
    screen: "Segment",
  },
  segmentHeader: {
    eventName: "RecordSession",
    renewedOn: new Date("2023-11-16"),
    screen: "Segment",
  },
  tripPlannerRecommendations: {
    eventName: "TripPlannerScreen",
    renewedOn: new Date("2024-10-09"),
    screen: "TripPlanner",
    chance: 1,
    isPersisting: true,
  },
};

// hotjarPoints that do not need to persist have an expiry of 30 days by default
// so that it can free up resources for more recent hotjarPoints that requires more focus
export function calculateHotjarChance(
  id: HotjarOrigin,
  hotjarConfig: HotjarConfig
): number {
  const hotjarPoint = hotjarConfig[id];
  const isExpired =
    !hotjarPoint.isPersisting &&
    new Date().getTime() - hotjarPoint.renewedOn.getTime() >=
      DEFAULT_VALID_DURATION_DAYS * ONE_DAY_IN_MILLISECONDS;

  if (isExpired) return 0;
  return hotjarPoint.chance !== undefined ? hotjarPoint.chance : 1;
}
