import { UseMutationResult, useMutation } from "@tanstack/react-query";
import {
  Dispatch,
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsInteractionEvent";
import { isLoggedIn } from "src/auth/utils/session";
import { HistoryAction } from "src/utils/historyReducer";
import useUser from "src/utils/hooks/useUser";
import { useFeature } from "src/feature/useFeature";
import { useTypedLocation } from "src/utils/hooks/useTypedLocation";
import { Action, TripPlannerDetails } from "../TripPlannerProvider";
import { ApiState, TripPlanWithID } from "../util/api";
import { tripPlannerHistoryReducer } from "../util/tripPlannerHistoryReducer";
import { useInitialTripFromSearch } from "./useInitialTripFromSearch";
import { usePostTripPlan } from "./usePostTripPlan";
import { usePutTripPlan } from "./usePutTripPlan";
import useUpdatePathForTrip from "./useUpdatePathForTrip";
import {
  MAX_SEARCH_PARAM_PLACES,
  useSwitchURLToTripId,
} from "./useSwitchURLToTripId";

export type TripPlannerMutationReturnType = UseMutationResult<
  TripPlannerDetails,
  unknown,
  TripPlannerDetails,
  unknown
>;

export function useTripPlanSync() {
  const { user } = useUser();
  const sendToRemote = usePutTripPlan();
  const initRemoteTripPlan = usePostTripPlan();
  const { updateTripPath, updatedPathRef } = useUpdatePathForTrip();
  const { switchToTripId } = useSwitchURLToTripId();
  const isTripURLFeature = useFeature("TripURL");

  const [{ present: tripPlannerDetails, future, past }, dispatch] = useReducer(
    tripPlannerHistoryReducer,
    null,
    () => {
      return {
        past: [],
        present: { transport: {}, places: [] },
        future: [],
      };
    }
  );

  const location = useTypedLocation();

  const mutate = useMutation({
    mutationKey: ["tripPlan"],
    mutationFn: async (tripPlannerDetails: TripPlannerDetails) => {
      if (isTripURLFeature) {
        if (tripPlannerDetails.id) {
          return await sendToRemote(
            tripPlannerDetails as TripPlanWithID,
            !!user
          );
        }
        const isTransportSelected = Object.keys(
          tripPlannerDetails.transport
        ).length;
        if (
          tripPlannerDetails.places.length > MAX_SEARCH_PARAM_PLACES ||
          user?.id ||
          isTransportSelected
        ) {
          return await initRemoteTripPlan(tripPlannerDetails, !!user);
        }
        return;
      }

      if (!tripPlannerDetails.id) {
        return await initRemoteTripPlan(tripPlannerDetails, !!user);
      } else {
        return await sendToRemote(tripPlannerDetails as TripPlanWithID, !!user);
      }
    },
    onMutate: (tripPlannerDetails: TripPlannerDetails) => {
      updateTripPath(tripPlannerDetails);
      const eventLabel = tripPlannerDetails.places
        .map((place) => place.canonicalName)
        .join("|");
      sendAnalyticsInteractionEvent(
        "TripPlanner",
        "TripPlanUpdated",
        eventLabel
      );
    },
    onSuccess: (data) => {
      if (!data) {
        return;
      }
      if (isTripURLFeature) {
        if (data.slug) {
          switchToTripId({
            tripId: data.slug,
            pathname: updatedPathRef.current,
            tripPlannerDetails: data,
          });
        }
        if (tripPlannerDetails.id === data.id) {
          dispatch({ type: "PASSIVE_UPDATE", trip: data });
        } else {
          dispatch({ type: "SET_TRIP", trip: data });
        }
        return;
      }
      if (data.id && tripPlannerDetails.id !== data.id) {
        dispatch({ type: "PASSIVE_UPDATE", trip: data });
      }
    },
  });

  // Browser forward and back button navigation syncing
  useEffect(() => {
    if (location.state?.tripPlannerDetails) {
      dispatch({
        type: "PASSIVE_UPDATE",
        trip: location.state?.tripPlannerDetails,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  async function dispatchAndSendToRemote(action: HistoryAction<Action>) {
    dispatch(action);
    let newTripPlannerDetails = tripPlannerHistoryReducer(
      { past, present: tripPlannerDetails, future },
      action
    ).present;
    await mutate.mutateAsync(newTripPlannerDetails);
  }

  const { isLoadingRemote } = useRemoteTripPlan(dispatch);
  useAssociateTripPlanWithAccount(
    dispatchAndSendToRemote,
    tripPlannerDetails,
    user?.id
  );

  return {
    dispatch: dispatchAndSendToRemote,
    tripPlannerDetails: [tripPlannerDetails],
    fetchState: (isLoadingRemote
      ? "fetching"
      : "fetched") as ApiState["fetchState"],
    history: {
      canRedo: future.length > 0,
      canUndo: past.length > 0,
    },
  };
}

export function useAssociateTripPlanWithAccount(
  dispatch: Dispatch<Action>,
  tripPlannerDetails: TripPlannerDetails,
  userId?: string
) {
  // This is to prevent unnecessary requests.
  // If the user was never logged out, we don't need to make any requests when they log in.
  const wasLoggedOut = useRef(!isLoggedIn());
  const [syncedUserId, setSyncedUserId] = useState<string | null>(null);

  const syncTripPlanWithAccount = useCallback(async () => {
    if (!userId || !wasLoggedOut.current) {
      return;
    }
    dispatch({ type: "PASSIVE_UPDATE", trip: tripPlannerDetails });
    setSyncedUserId(userId);
  }, [dispatch, tripPlannerDetails, userId]);

  useEffect(() => {
    if (
      userId &&
      userId !== syncedUserId &&
      tripPlannerDetails.places.length > 0
    ) {
      syncTripPlanWithAccount();
    }
  }, [
    userId,
    syncTripPlanWithAccount,
    syncedUserId,
    tripPlannerDetails.places,
  ]);

  useEffect(() => {
    if (!userId) {
      wasLoggedOut.current = true;
      setSyncedUserId(null);
    }
  }, [userId]);
}

function useRemoteTripPlan(dispatch: Dispatch<Action>) {
  const { addTripFromSearch, hasUsedSearch } =
    useInitialTripFromSearch(dispatch);

  useEffect(() => {
    addTripFromSearch?.();
  }, [addTripFromSearch]);

  return {
    isLoadingRemote: !hasUsedSearch,
  };
}
