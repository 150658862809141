import markerSDK, { MarkerSdk } from "@marker.io/browser";
import { useEffect, useRef, useState } from "react";
import Cookies from "js-cookie";
import { useFeatureConfig } from "src/feature/useFeatureConfig";
import { FeatureConfig, FeatureName } from "src/feature/FeatureConfig";
import { FREELANCER_MARKER_LOGIN_COOKIE } from "src/domain/FreelancerMarkerLogin/FreelancerMarkerLogin";
import { setSearchParamsFeature } from "src/feature/internal/searchParams/useSearchParamsFeatures";
import { useNavigate } from "react-router";
import { searchParamsChange } from "../location/searchParamsChange";
import { useTypedLocation } from "./useTypedLocation";
import { useGetPermaLink } from "./useGetPermaLink";

type MarkerFeature = {
  featureName: FeatureName;
  markerId: string;
};

function getMarkerProjectFromFeature(frontendFeatures: FeatureConfig) {
  // Add a frontend feature to markerProjects along with
  // an active Marker.io project ID. When that feature is
  // active in the browser, it will trigger the Marker.io widget
  // for the Marker.io project associated with that feature.
  // Be selective with which feature name you associate
  // with a Marker.io project, as only the first match will
  // be used to trigger the form widget in the browser.
  const markerProjects: MarkerFeature[] = [
    {
      featureName: "DaytripQA",
      markerId: "66ecd16729b78ee05f831892",
    },
    {
      featureName: "TimeOfDayFilter",
      markerId: "6705fd89988cd1483600cdd9",
    },
    {
      featureName: "DestinationPagesQA",
      markerId: "670605e7b174a83505eb6364",
    },
  ];

  let currentProjectId = "";

  for (let markerProject of markerProjects) {
    if (frontendFeatures[markerProject.featureName]) {
      currentProjectId = markerProject.markerId;
      break;
    }
  }

  return currentProjectId;
}

function getFreelancerCredentials() {
  const cookieValue = Cookies.get(FREELANCER_MARKER_LOGIN_COOKIE);

  if (!cookieValue) {
    return;
  }

  const [email, fullName] = cookieValue.split("|");

  return {
    email,
    fullName,
  };
}

export function useMarker() {
  const marker = useRef<MarkerSdk | undefined>();
  const permalink = useGetPermaLink().href;
  const frontendFeatures = useFeatureConfig();
  const location = useTypedLocation();
  const navigate = useNavigate();
  const [projectId, setProjectId] = useState<string>("");

  const freelancerCredentials =
    frontendFeatures.FreelancerQA !== "hide" && getFreelancerCredentials();

  useEffect(() => {
    const freelanceQaProjectId = "66ff4fcee3ed7bfa489136a1";
    const currentProjectId = freelancerCredentials
      ? freelanceQaProjectId
      : getMarkerProjectFromFeature(frontendFeatures);

    if (
      freelancerCredentials &&
      !frontendFeatures.LogAnalyticsEventsToConsole
    ) {
      const newSearchParams = setSearchParamsFeature(
        "LogAnalyticsEventsToConsole",
        true,
        location.search
      );
      navigate(searchParamsChange(newSearchParams, location), {
        replace: true,
      });
    }

    setProjectId(currentProjectId);

    marker.current?.setCustomData({
      permalink,
    });

    // The permalink will always update if a FE feature changes
    // so we just listen to the permalink as the dependency.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permalink]);

  useEffect(() => {
    async function loadMarker() {
      // Initialise Marker script
      return await markerSDK.loadWidget({
        project: projectId,
        customData: {
          permalink,
        },
        ...(freelancerCredentials && {
          reporter: {
            email: freelancerCredentials.email,
            fullName: freelancerCredentials.fullName,
          },
        }),
      });
    }

    if (projectId && marker.current === undefined) {
      loadMarker()
        .then((widget) => {
          marker.current = widget;
        })
        .catch((error) => console.warn(error));
    }

    if (projectId) {
      marker.current?.show();
    } else {
      marker.current?.hide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);
}
