import { defineMessages } from "react-intl";

export const TopOverviewMessages = defineMessages({
  addToTrip: {
    defaultMessage: "Add to trip",
    id: "tripPlanner.saveButton.add",
    description: "To let users know they can save item to their trip",
  },
  addedToTrip: {
    defaultMessage: "Added to trip",
    id: "tripPlanner.saveButton.added",
    description: "To let users know they have saved an item to their trip",
  },
  viewMore: {
    id: "tripPlanner.topOverview.viewMore",
    description: "Label on button that reveals extra readable text",
    defaultMessage: "View more",
  },
  viewLess: {
    id: "tripPlanner.topOverview.viewLess",
    description: "Label on button that hides extra readable text",
    defaultMessage: "View less",
  },
});
