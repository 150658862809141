import { useState } from "react";
import Cookies from "js-cookie";
import { CloseButton } from "src/auth/components/CloseButton/CloseButton";
import { Dialog } from "src/components/Dialog/Dialog";
import { Button } from "src/design-system/components/Button/Button";
import {
  BodyMd,
  HeadingMd,
  Label,
} from "src/design-system/components/Typography/Typography";
import color from "src/design-system/tokens/color";
import { spacing } from "src/design-system/tokens/spacing";
import { fontSize } from "src/theme";
import styled from "styled-components";
import { useNavigate } from "react-router";
import { searchParamsChange } from "src/utils/location/searchParamsChange";
import { setSearchParamsFeature } from "src/feature/internal/searchParams/useSearchParamsFeatures";
import { useTypedLocation } from "src/utils/hooks/useTypedLocation";

export const FREELANCER_MARKER_LOGIN_COOKIE = "freelancerMarkerLogin";

export function FreelancerMarkerLogin() {
  const navigate = useNavigate();
  const location = useTypedLocation();
  const [isOpen, setIsOpen] = useState(true);
  const [formData, setFormData] = useState({
    email: "",
    fullName: "",
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const credentials = `${formData.email}|${formData.fullName}`;
    Cookies.set(FREELANCER_MARKER_LOGIN_COOKIE, credentials, { expires: 365 });

    const newSearchParams = setSearchParamsFeature(
      "FreelancerQA",
      "default",
      location.search
    );
    navigate(searchParamsChange(newSearchParams, location), {
      replace: true,
    });
  };

  return (
    <Dialog isOpen={isOpen} onBackdropClicked={() => setIsOpen(false)}>
      <Wrapper>
        <CloseModalWrapper>
          <CloseButton onClick={() => setIsOpen(false)} />
        </CloseModalWrapper>

        <Form onSubmit={handleSubmit}>
          <Title>Marker Freelancer Login</Title>
          <Description>
            Please log in to access the Marker QA widget.
          </Description>
          <InputWrapper>
            <StyledLabel size="md" weight="bold">
              Email:
            </StyledLabel>
            <Input
              type="email"
              placeholder="Email"
              name="email"
              onChange={handleInputChange}
              autoComplete="new-password"
              required
            />
          </InputWrapper>
          <InputWrapper>
            <StyledLabel size="md" weight="bold">
              Full name:
            </StyledLabel>
            <Input
              type="text"
              placeholder="Full Name"
              name="fullName"
              onChange={handleInputChange}
              required
            />
          </InputWrapper>
          <SubmitButton type="submit">Log in</SubmitButton>
        </Form>
      </Wrapper>
    </Dialog>
  );
}

const Wrapper = styled.div`
  position: relative;
  padding: ${spacing.xxxl};
`;

const CloseModalWrapper = styled.div`
  position: absolute;
  top: ${spacing.md};
  right: ${spacing.md};

  &:hover {
    opacity: 0.6;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`;

const Title = styled(HeadingMd)`
  margin-bottom: ${spacing.sm};
  text-align: center;
`;

const Description = styled(BodyMd)`
  margin-bottom: ${spacing.xxl};
  text-align: center;
`;

const InputWrapper = styled.div`
  width: 250px;
  margin: 0 auto ${spacing.xl};
`;

const StyledLabel = styled(Label)`
  display: block;
  margin-bottom: ${spacing.md};
  color: ${color.text.secondary};
`;

const Input = styled.input`
  width: 100%;
  border: 1px solid ${color.border.border};
  border-radius: ${spacing.md};
  background-color: ${color.input.bgSurfaceActive};
  font-size: ${fontSize.md};
  padding: ${spacing.lg};

  &:hover {
    border-color: ${color.text.text};
  }

  &:focus {
    outline: none;
  }
`;

const SubmitButton = styled(Button)`
  padding-left: ${spacing.xxxl};
  padding-right: ${spacing.xxxl};
  margin: ${spacing.xl} auto 0;
`;
