import { useIntl } from "react-intl";
import { useLogExitCallback } from "src/analytics/useLogExitCallback/useLogExitCallback";
import { formatPrice } from "src/components/FormattedPrice/FormattedPrice";
import { Skeleton } from "src/components/Skeleton/Skeleton";
import HotelGallery from "src/domain/HotelsScreen/HotelGallery/HotelGallery";
import { useHotelsContext } from "src/domain/HotelsScreen/HotelsContext";
import StarRating from "src/domain/HotelsScreen/StarRating";
import { HotelViewModel } from "src/domain/HotelsScreen/hotelsScreenAdapter";
import { exitToProviderWithLogging } from "src/domain/HotelsScreen/utils-exit-to-provider";
import { messages } from "src/domain/SegmentScreen/DynamicSchedules/Schedule/Schedule.messages";
import { borderRadius, color, fontSize, fontWeight, spacing } from "src/theme";
import { useHotelInfo } from "src/utils/hooks/useHotelInfo";
import useSearch from "src/utils/hooks/useSearch";
import styled from "styled-components";
import { localeToLanguageCode } from "src/utils/conversions/languageCode";

export function TripHotelCard({
  hotelId,
  provider,
  price,
  starRating,
}: HotelViewModel) {
  const intl = useIntl();
  const { searchResponse, destination: searchDestination } = useSearch();
  const { stayDatesUI, destination, roomDetailsUI } = useHotelsContext();
  const { hotelInfoResponse } = useHotelInfo({ hotelId, provider });
  const hotelUrl = hotelInfoResponse?.hotelUrl;

  const logExitCallback = useLogExitCallback(
    "Hotels",
    "tripPlannerHotels",
    `hotelCard`
  );

  function exitToHotel(event: React.MouseEvent) {
    event.stopPropagation();
    exitToProviderWithLogging({
      variant: "tripCard",
      actionType: "Click",
      hotelUrl: hotelUrl,
      logExitCallback,
      provider,
      destinationName: destination?.shortName,
      destinationState:
        searchDestination && "regionName" in searchDestination
          ? searchDestination.regionName
          : undefined,
      hotelName: hotelInfoResponse?.name,
      hotelStarRating: hotelInfoResponse?.starRating,
      stayDates: stayDatesUI,
      languageCode: localeToLanguageCode(intl.locale),
      searchResponse,
      avgDailyRate: price.value,
      roomDetails: roomDetailsUI,
      isCrimtanEnabled:
        searchResponse?.analytics?.interest_data?.Crimtan?.enabled,
      isAdaraEnabled: searchResponse?.analytics?.interest_data?.Adara?.enabled,
    });
  }

  return (
    <HotelCardContainer onClick={exitToHotel}>
      <FrameDiv>
        <HotelGallery
          src={hotelInfoResponse?.imageUrl}
          provider={provider}
          variant="trip"
        />
        <RatingWrapper $show={!!starRating}>
          <StarRating starRating={starRating} iconSize="xs" />
        </RatingWrapper>
      </FrameDiv>
      <FormattedPrice>
        <PriceFrom>{intl.formatMessage(messages.from)}</PriceFrom>
        <BestHotelPrice>{formatPrice(price, intl)}</BestHotelPrice>
      </FormattedPrice>
    </HotelCardContainer>
  );
}

export function HotelCardLoading() {
  return (
    <HotelCardContainer>
      <HotelGallery variant="trip" />
      <FormattedPrice>
        <Skeleton width={60} height={20} />
      </FormattedPrice>
    </HotelCardContainer>
  );
}

const FrameDiv = styled.div`
  position: relative;
`;

const RatingWrapper = styled.div<{ $show: boolean }>`
  --overlay-color: rgba(31, 30, 30, 0.2);
  display: ${(props) => (props.$show ? "unset" : "none")};
  position: absolute;
  left: 0;
  bottom: 0;
  margin: ${spacing.sm};
  border-radius: ${borderRadius.lg};
  background-color: var(--overlay-color);
  box-shadow: 0px 0px ${spacing.sm} ${spacing.sm} var(--overlay-color);
`;

const HotelCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.sm};

  &:hover {
    cursor: pointer;
  }
`;

const FormattedPrice = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: baseline;
  gap: ${spacing.sm};
`;

const BestHotelPrice = styled.div`
  font-size: ${fontSize.lg};
  font-weight: ${fontWeight.semibold};
`;

const PriceFrom = styled.div`
  font-size: ${fontSize.sm};
  font-weight: ${fontWeight.normal};
  color: ${color.n100};
`;
