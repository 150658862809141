import { FormattedPriceRange } from "src/components/FormattedPriceRange/FormattedPriceRange";
import {
  color as themeColor,
  fontWeight,
  spacing,
  borderRadius,
} from "src/theme";
import styled from "styled-components";
import {
  BodyLg,
  HeadingMd,
  LabelMd,
} from "src/design-system/components/Typography/Typography";
import color from "src/design-system/tokens/color";

import { PriceRange } from "src/utils/adapters/priceRange";
import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsInteractionEvent";
import { useState } from "react";
import { useLayout } from "src/utils/hooks/useLayout";
import { Icon } from "src/components/Icon/Icon";
import ToolTip from "src/svg/ToolTip.svg?react";
import Close from "src/svg/Close.svg?react";
import { MiniVariantDrawer } from "../../SegmentScreenLoaded/UIDesignElements/MiniVariantDrawer";

type ToolTipProps = {
  priceRange?: PriceRange;
  toolTipCta?: string;
  title?: string;
  messageBody?: string;
  analyticsId?: string;
};

export function ToolTipInfoPopup({
  priceRange,
  title,
  messageBody,
  toolTipCta,
  analyticsId,
}: ToolTipProps) {
  const [isToolTipOpen, setIsToolTipOpen] = useState(false);
  const isMobile = useLayout() === "mobile";

  const handleShowTooltip = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (analyticsId) {
      sendAnalyticsInteractionEvent("Segment", `Click:${analyticsId}`);
    }
    setIsToolTipOpen(true);
  };

  const handleCloseTooltip = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsToolTipOpen(false);
    if (analyticsId) {
      sendAnalyticsInteractionEvent("Segment", `Click:Dismiss${analyticsId}`);
    }
  };

  const toolTipBox = (
    <Container>
      <InfoButton
        tabIndex={0}
        onClick={handleShowTooltip}
        promoVariant={!priceRange}
      >
        {priceRange ? (
          <StyledFormattedPrice {...priceRange!} />
        ) : (
          <LabelMd decoration="underline" color={color.text.primary.primary}>
            {toolTipCta}
          </LabelMd>
        )}
        <ToolTipIcon size={priceRange ? "xl" : "md"} promoVariant={!priceRange}>
          <ToolTip color={priceRange ? "n50" : color.text.secondary} />
        </ToolTipIcon>
      </InfoButton>
      {isToolTipOpen && !isMobile && (
        <ToolTipDialog
          promoVariant={!priceRange}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {priceRange ? (
            <Arrow />
          ) : (
            <Wrapper>
              <HeadingMd color={color.icon.icon}>{title}</HeadingMd>
              <CloseIcon onClick={handleCloseTooltip}>
                <Close color={color.text.tertiary} />
              </CloseIcon>
            </Wrapper>
          )}
          <BodyLg
            color={
              !priceRange ? color.text.primary.primary : color.text.onBgFill
            }
          >
            {messageBody}
          </BodyLg>
        </ToolTipDialog>
      )}
    </Container>
  );

  return (
    <>
      {isToolTipOpen && !isMobile && (
        <Overlay className="tooltip-active" onClick={handleCloseTooltip} />
      )}
      {toolTipBox}
      {isMobile && (
        <MiniVariantDrawer
          isOpen={isToolTipOpen}
          setIsOpen={setIsToolTipOpen}
          title={title}
          messageBody={messageBody}
          analyticsId={analyticsId}
        />
      )}
    </>
  );
}

const Overlay = styled.div`
  position: fixed;
  inset: 0;
  cursor: default;
  z-index: 1;
`;

const InfoButton = styled.div<{ promoVariant: boolean }>`
  cursor: pointer;
  margin-top: ${(props) => props.promoVariant && `${spacing.sm}`};
  &:hover span {
    text-decoration: ${(props) => props.promoVariant && `none`};
  }
`;
const ToolTipDialog = styled.div<{ promoVariant: boolean }>`
  ${(props) => {
    if (props.promoVariant) {
      return `
      bottom: 22px;
      padding: 20px;
      border-radius: ${borderRadius.lg};
      background-color: ${themeColor.white};
      min-width: 360px;
      box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.15);
      z-index: 2;
      `;
    } else {
      return `
      top: 48px;
      left: -40px;
      background-color: rgba(0, 0, 0, 0.7);
      min-width: 264px;
      padding: ${spacing.lg};
      border-radius: ${borderRadius.md};
      `;
    }
  }}
  position: absolute;
  display: flex;
  flex-direction: column;
  cursor: default;

  &:last-child {
    gap: ${spacing.xl};
  }
`;

const Container = styled.div`
  position: relative;
  display: inline-block;
`;

const Arrow = styled.div`
  position: absolute;
  top: -10px;
  left: ${spacing.xxl};

  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid ${themeColor.black};

  opacity: 0.7;
`;
const StyledFormattedPrice = styled(FormattedPriceRange)`
  text-decoration: underline;
  font-weight: ${fontWeight.medium};
`;
const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${spacing.xl};
`;

const ToolTipIcon = styled(Icon)<{ promoVariant: boolean }>`
  margin-bottom: ${spacing.xs};
  vertical-align: middle;
  margin-left: ${(props) =>
    props.promoVariant ? ` ${spacing.md}` : ` ${spacing.sm} `};
`;

const CloseIcon = styled.div`
  width: 20px;
  height: 20px;
  padding: ${spacing.sm};
  cursor: pointer;
`;
