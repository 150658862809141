import { useIntl } from "react-intl";
import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsInteractionEvent";
import { ButtonBase } from "src/components/Button/ButtonBase";
import { Icon } from "src/components/Icon/Icon";
import { TripPlannerTransportKey } from "src/domain/TripPlanner/TripPlannerProvider";
import { useTripPlannerContext } from "src/domain/TripPlanner/hooks/useTripPlannerContext";
import { Close } from "src/svg/Close";
import {
  borderRadius,
  color,
  fontSize,
  fontWeight,
  iconSize,
  lineHeight,
  spacing,
} from "src/theme";
import styled, { css } from "styled-components";
import { DragHandle } from "src/svg/tripplanner/DragHandle";
import { GeocodedPlace } from "src/PrefetchData";
import { useCallback, useState } from "react";
import { AutocompletePlace } from "src/api/AutocompleteResponse";
import { DraggableProvidedDragHandleProps } from "@hello-pangea/dnd";
import { Edit } from "src/svg/tripplanner/Edit";
import { autocompleteToGeocodedPlace } from "src/utils/autocompleteToGeocodedPlace";
import { localeToLanguageCode } from "src/utils/conversions/languageCode";
import { useApiConfig } from "src/api/ApiConfigProvider";
import { useQueryClient } from "@tanstack/react-query";
import { AddDestinationDialog } from "../../AddDestinationButton/AddDestinationDialog/AddDestinationDialog";
import { placeNumberOffsetPx } from "../../Headings/PlaceTitle";
import messages from "./TripCardHeading.messages";

type Props = {
  canonicalPair: TripPlannerTransportKey;
  geocodedPlace: GeocodedPlace;
  index: number;
  draggableProperties?: Partial<DraggableProvidedDragHandleProps> & {
    "data-rbd-drag-handle-draggable-id": string;
  };
  isDragEnding?: boolean;
};

export function TripCardHeading({
  canonicalPair,
  index,
  geocodedPlace,
  draggableProperties,
  isDragEnding,
}: Props) {
  const intl = useIntl();
  const languageCode = localeToLanguageCode(intl.locale);
  const apiConfig = useApiConfig();
  const queryClient = useQueryClient();
  const { dispatch } = useTripPlannerContext();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleEditDrawer = useCallback(() => {
    const action = `${!isDrawerOpen ? "Open" : "Close"}:EditDestination`;
    sendAnalyticsInteractionEvent("TripPlanner", action);
    setIsDrawerOpen(!isDrawerOpen);
  }, [isDrawerOpen, setIsDrawerOpen]);

  const onSelectDestination = useCallback(
    (newPlace: AutocompletePlace, place: GeocodedPlace) => {
      setIsDrawerOpen(false);
      if (newPlace.canonicalName !== place.canonicalName) {
        sendAnalyticsInteractionEvent(
          "TripPlanner",
          "Select:EditDestinationInline",
          newPlace.canonicalName
        );
        autocompleteToGeocodedPlace(
          newPlace,
          (geocodedPlace: GeocodedPlace) =>
            dispatch({
              type: "EDIT_DESTINATION",
              index,
              newPlace: geocodedPlace,
            }),
          languageCode,
          queryClient,
          apiConfig
        );
      }
    },
    [apiConfig, dispatch, index, languageCode, queryClient]
  );

  const onClickDelete = useCallback(() => {
    sendAnalyticsInteractionEvent(
      "TripPlanner",
      "Click:RemoveDestination",
      canonicalPair.split("_")[0]
    );
    dispatch({
      type: "REMOVE_PLACE",
      index,
    });
  }, [canonicalPair, dispatch, index]);

  return (
    <Container
      data-testid={`trip-card-heading-${index}`}
      {...draggableProperties}
    >
      <DragHandleButton
        data-testid={`drag-handle-${draggableProperties?.["data-rbd-drag-handle-draggable-id"]}`}
      >
        <Icon size="xxl">
          <DragHandle
            title={intl.formatMessage(messages.dragPlace)}
            tint="cod"
          />
        </Icon>
      </DragHandleButton>
      <InnerContentContainer>
        <PlaceDetailsContainer>
          <PlaceNumber isDragEnding={isDragEnding}>{index + 1}</PlaceNumber>
          <PlaceNameButton
            title={intl.formatMessage(messages.edit)}
            onClick={toggleEditDrawer}
            data-testid="place-name-btn"
          >
            {geocodedPlace.longName ?? geocodedPlace.shortName}
            <NonBreakingSpan>
              &nbsp;
              <PlaceEdit data-testid="edit-place">
                <Icon size="sm">
                  <Edit title={intl.formatMessage(messages.edit)} tint="n100" />
                </Icon>
              </PlaceEdit>
            </NonBreakingSpan>
          </PlaceNameButton>
        </PlaceDetailsContainer>
        <PlaceDeleteButton
          data-testid="remove-place-btn"
          onClick={onClickDelete}
        >
          <Icon size="sm-1">
            <Close
              title={intl.formatMessage(messages.removePlace)}
              tint="n100"
            />
          </Icon>
        </PlaceDeleteButton>
      </InnerContentContainer>

      <AddDestinationDialog
        onSelectOption={(newPlace) => {
          onSelectDestination(newPlace, geocodedPlace);
        }}
        onBackdropClicked={toggleEditDrawer}
        onCloseClicked={toggleEditDrawer}
        isOpen={isDrawerOpen}
        place={geocodedPlace}
        initialValue={geocodedPlace.longName ?? geocodedPlace.shortName}
      />
    </Container>
  );
}

const DragHandleButton = styled.div`
  position: absolute;
  left: 0;
  top: ${spacing.sm};
  transform: translateX(-100%);
  opacity: 0;
  cursor: grab;
  transition: opacity 0.3s ease, transform 0.3s ease;
`;
const NonBreakingSpan = styled.span`
  white-space: nowrap;
`;
const PlaceEdit = styled.div`
  display: inline-block;
  transition: opacity 0.3s ease;
  opacity: 0;
  margin: 0 ${spacing.sm} ${spacing.xs};
`;

const PlaceNameButton = styled(ButtonBase)`
  display: flex;
  align-items: flex-start;
  font-weight: ${fontWeight.medium};
  font-size: ${fontSize.h4};
  line-height: ${lineHeight.snug};
  color: ${color.cod};
  text-align: left;
  margin-top: calc(${placeNumberOffsetPx / 2}px - 0.15em);
  max-width: 28ch;
`;

const PlaceNumber = styled.span<{
  isDragEnding?: boolean;
}>`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
  font-weight: ${fontWeight.bold};
  font-size: ${fontSize.body};
  line-height: ${lineHeight.snug};
  border-radius: 50%;
  width: ${placeNumberOffsetPx * 2}px;
  height: ${placeNumberOffsetPx * 2}px;
  background-color: ${color.n300};
  margin: 0 ${spacing.md} 0 0;
  background-color: ${color.n300};
  color: ${color.white};

  transition: transform 0.3s ease-in-out;
  ${({ isDragEnding }) =>
    isDragEnding &&
    css`
      transform: scale(0.8);
      transition: transform 0.2s ease-in-out;
    `};
`;
const PlaceDeleteButton = styled(ButtonBase)`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  align-self: stretch;
  width: ${iconSize.xxxl};
  padding: 0.6em ${spacing.xs} 0;

  &:hover svg path {
    fill: ${color.n50};
  }

  opacity: 0;
  transition: opacity 0.3s ease;
`;
const PlaceDetailsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  border-radius: ${borderRadius.xl};
  cursor: grab;
  width: 100%;
`;

const InnerContentContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-radius: ${borderRadius.xl};
  transition: background-color 0.3s ease;
  margin-left: ${spacing.md};
`;

const Container = styled.div`
  margin-bottom: ${spacing.lg};
  position: relative;
  &:hover {
    ${InnerContentContainer} {
      background-color: ${color.n30};
    }
    ${PlaceDeleteButton}, ${DragHandleButton}, ${PlaceEdit} {
      opacity: 1;
    }
    ${DragHandleButton} {
      transform: translateX(-70%);
    }
  }
  &:focus,
  &:active {
    ${DragHandleButton} {
      opacity: 1;
      transform: translateX(-70%);
    }
    ${InnerContentContainer} {
      box-shadow: 0px 2px 8px 0px #00000026;
      background-color: ${color.n30};
    }
  }
`;
