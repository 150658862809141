import { PropsWithChildren } from "react";
import styled, { CSSProperties, css } from "styled-components";
import { color, spacing } from "src/theme";
import { placeNumberOffsetPx } from "src/components/TripPlanner/Headings/PlaceTitle";
import { DragUpdate, DroppableStateSnapshot } from "@hello-pangea/dnd";
import { useIsTripsAsCoreFullExperience } from "src/utils/hooks/useIsTripsAsCoreFullExperience";
import { useFeature } from "src/feature/useFeature";
import { useLayout } from "src/utils/hooks/useLayout";
import { useTripPlannerContext } from "../hooks/useTripPlannerContext";

type TripPlannerPlaceProps = PropsWithChildren<{
  index: number;
  isLast?: boolean;
  isDragging?: boolean;
  isBeingDragged?: boolean;
}>;

type DraggableTripPlannerPlaceProps = TripPlannerPlaceProps & {
  droppableState?: DroppableStateSnapshot;
  dragStatus?: DragUpdate;
};

export function TripPlannerPlace({
  index,
  children,
  isDragging,
  isBeingDragged,
  isLast,
}: TripPlannerPlaceProps) {
  const { loadingIndex, activeTripPlannerTab } = useTripPlannerContext();
  const isHotelTab = activeTripPlannerTab === "hotels";
  const isTripsAsCoreHoldback = useIsTripsAsCoreFullExperience();
  const isSimpleSearchV3 = useFeature("SimpleSearchV3");
  const { isMultiTrip } = useTripPlannerContext();
  const layout = useLayout();

  return (
    <TripPlanEntryWrapper
      $isDragging={isDragging}
      $isBeingDragged={isBeingDragged}
      $hideTimeline={isLast || isHotelTab}
      $loading={loadingIndex?.includes(index)}
      $isTripsAsCoreHoldback={isTripsAsCoreHoldback}
      $showOnlyTail={!isMultiTrip && isSimpleSearchV3 && layout === "desktop"}
    >
      {children}
    </TripPlanEntryWrapper>
  );
}

export function DraggableTripPlannerPlace(
  props: DraggableTripPlannerPlaceProps
) {
  const isBeingDragged = props.dragStatus?.source.index === props.index;

  return (
    <TripPlannerPlace
      {...props}
      isBeingDragged={isBeingDragged}
      isDragging={props.isDragging}
    />
  );
}

const tripPlanTimeline = css`
  position: relative;

  > * {
    position: relative;
  }

  &::before {
    content: "";
    width: 4px;
    height: 100%;
    display: block;
    background-color: ${color.n40};
    position: absolute;

    left: calc(${placeNumberOffsetPx}px + ${spacing.md} - 2px);
    top: ${spacing.xl};
  }
`;

const newUitripPlanTimeline = css`
  position: relative;

  > * {
    position: relative;
  }

  &::before {
    content: "";
    width: 2px;
    height: 100%;
    display: block;
    background-color: ${color.n30};
    position: absolute;

    top: ${spacing.xxxl};
    left: -45px;
  }
`;

const showTailOnlyTimeline = css`
  ${newUitripPlanTimeline}

  > * {
    z-index: 1;
  }

  &::before {
    height: 94px;
    top: unset;
    bottom: -${spacing.xxxl};
  }
`;

export const TripPlanEntryWrapper = styled.div<{
  $margin?: CSSProperties["margin"];
  $hideTimeline?: boolean;
  $loading?: boolean;
  $isDragging?: boolean;
  $isBeingDragged?: boolean;
  $isTripsAsCoreHoldback?: boolean;
  $showOnlyTail?: boolean;
}>`
  position: relative;
  display: ${({ $loading }) => ($loading ? "none" : "block")};
  margin: ${({ $margin }) => $margin ?? 0};
  width: 100%;

  ${(props) => {
    if (
      !props.$hideTimeline &&
      !props.$isTripsAsCoreHoldback &&
      !props.$showOnlyTail
    ) {
      return tripPlanTimeline;
    }
  }}

  ${(props) => {
    if (
      !props.$hideTimeline &&
      props.$isTripsAsCoreHoldback &&
      !props.$showOnlyTail
    ) {
      return newUitripPlanTimeline;
    }
  }}


  ${(props) => {
    if (props.$showOnlyTail) {
      return showTailOnlyTimeline;
    }
  }}

  ${({ $isDragging }) =>
    $isDragging &&
    css`
      &:before {
        height: 80%;
      }
    `}
`;
