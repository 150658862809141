export const GOOGLE_MAPS_KEY = "AIzaSyATpzcSUwJuZnsWa8_jP7SKKnOaiuK6mRU";
export const GOOGLE_MAP_ID = "dcb3fab075efb002";
export const MAX_ZOOM_LEVEL = 20;
export const MIN_ZOOM_LEVEL = 2;
export const DEFAULT_MAP_ZOOM = 12;
export const DEFAULT_MAP_RADIUS = 25;
export const DEFAULT_TRIP_MAP_ZOOM = 20;
export const MAP_SAFETY_MARGIN = 1.25;
/**
 * This configuration prevents users from scrolling endlessly
 * into the greyspace above and below the map.
 * https://developers.google.com/maps/documentation/javascript/reference/map#MapRestriction.latLngBounds
 */
export const MAP_BOUNDS_RESTRICTION = {
  restriction: {
    latLngBounds: {
      east: 180,
      north: 85,
      south: -85,
      west: -180,
    },
    strictBounds: true,
  },
} as const;
