import { useIntl } from "react-intl";
import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsInteractionEvent";
import { LogoLink, LogoOption, Nav } from "src/domain/TopNav/TopNav";
import { Rome2rioLogo } from "src/svg/Rome2rioLogo";
import { localeToLanguageCode } from "src/utils/conversions/languageCode";
import { getHomepageUrl } from "src/utils/url";
import styled from "styled-components";
import { messages } from "src/domain/TopNav/TopNav.messages";
import { useTheme } from "src/utils/hooks/useTheme";
import GoogleMapReact from "@rome2rio/google-map-react";
import {
  GOOGLE_MAP_ID,
  MAP_BOUNDS_RESTRICTION,
} from "src/components/Map/constants";
import { HoveredIndexProvider } from "src/HoveredIndexProvider";
import { screenMinWidth, spacing } from "src/theme";
import { useMapBootstrapURLKeys } from "src/utils/hooks/useMapBootstrapURLKeys";

export default function Discover() {
  const intl = useIntl();
  const languageCode = localeToLanguageCode(intl.locale);
  const homepageUrl = getHomepageUrl(languageCode);
  const theme = useTheme();

  return (
    <HoveredIndexProvider>
      <DiscoverDiv>
        <DiscoverNav isLargeNav>
          <LogoOption $small as="div">
            <LogoLink
              href={homepageUrl}
              onClick={() =>
                sendAnalyticsInteractionEvent("TopNav", "Click:Home")
              }
            >
              <Rome2rioLogo
                title={intl.formatMessage(messages.homepage)}
                tint={theme.topNav.iconTint}
                dotTint="pink"
              />
            </LogoLink>
          </LogoOption>
        </DiscoverNav>
        <DiscoverPane></DiscoverPane>
        <DiscoverMap />
      </DiscoverDiv>
    </HoveredIndexProvider>
  );
}

function DiscoverMap() {
  function handleApiLoaded(
    googleMap?: google.maps.Map,
    googleMaps?: typeof google.maps
  ) {
    if (!googleMaps || !googleMap) {
      return;
    }
    window.dispatchEvent(new Event("mapApiLoaded"));
  }
  const options: GoogleMapReact.MapOptions = {
    fullscreenControl: false,
    clickableIcons: false,
    maxZoom: 20,
    gestureHandling: "greedy",
    disableDoubleClickZoom: false,
    minZoom: 0,
    ...MAP_BOUNDS_RESTRICTION,
  };

  const bootstrapURLKeys = useMapBootstrapURLKeys();

  return (
    <MapDiv>
      <GoogleMapReact
        bootstrapURLKeys={bootstrapURLKeys}
        defaultCenter={{ lat: 41.8719, lng: 12.5674 }}
        defaultZoom={6}
        options={{
          ...options,
          mapId: GOOGLE_MAP_ID,
        }}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
      ></GoogleMapReact>
    </MapDiv>
  );
}

const MapDiv = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
  touch-action: none; // To prevent mobile browsers from being refreshed when a user drags down on the map
  position: relative;
`;

const DiscoverDiv = styled.div`
  position: fixed;
  display: grid;
  grid-template-columns: min-content auto;
  grid-template-rows: min-content auto;
  height: 100%;
  width: 100%;
`;

const DiscoverNav = styled(Nav)`
  grid-column: 1 / 3;
`;

const DiscoverPane = styled.div`
  overflow-y: auto;
  padding-bottom: ${spacing.xxxxl};
  width: 384px;
  ${screenMinWidth.md} {
    width: 496px;
  }
  ${screenMinWidth.xl} {
    width: 576px;
  }
`;
