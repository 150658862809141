import { Dispatch, SetStateAction } from "react";
import { Drawer2 } from "src/components/Drawer2/Drawer2";
import { Drawer2Content } from "src/components/Drawer2/Drawer2Content/Drawer2Content";
import { Drawer2Title } from "src/components/Drawer2/Drawer2Title/Drawer2Title";
import { BorderLine } from "src/components/CommonComponents/CommonComponents";
import styled from "styled-components";
import { spacing } from "src/theme";
import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsInteractionEvent";

type Props = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  title?: string;
  messageBody?: string;
  analyticsId?: string;
};

export const MiniVariantDrawer = ({
  isOpen,
  setIsOpen,
  title,
  messageBody,
  analyticsId,
}: Props) => {
  const closeDrawerHandler = () => {
    setIsOpen(false);
    if (analyticsId) {
      sendAnalyticsInteractionEvent("Segment", `Click:Dismiss${analyticsId}`);
    }
  };
  return (
    <Drawer2 isOpen={isOpen} onBackdropClicked={closeDrawerHandler}>
      <Drawer2Title onCloseClicked={closeDrawerHandler}>{title}</Drawer2Title>
      <Drawer2Content removePadding removeMargin>
        <BorderLine />
        <Text>{messageBody}</Text>
      </Drawer2Content>
    </Drawer2>
  );
};

const Text = styled.p`
  padding: ${spacing.xxl};
  text-align: center;
`;
