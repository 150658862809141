import { GOOGLE_MAPS_KEY } from "src/components/Map/constants";
import useUser from "./useUser";

export function useMapBootstrapURLKeys() {
  const languageCode = useUser().language;

  return {
    key: GOOGLE_MAPS_KEY,
    libraries: "geometry",
    language: languageCode,
  };
}
