import { defineMessages } from "react-intl";

export const messages = defineMessages({
  bookPrivateTransfer: {
    id: "daytripPromo.bookPrivateTransfer",
    description:
      "The title that is displayed on the CTA to book a private transfer",
    defaultMessage: "Book a private transfer",
  },
  whatIsPrivateTransfer: {
    id: "daytripPromo.whatIsPrivateTransfer",
    description:
      "Tooltip text which when clicked, shows what a private transfer is",
    defaultMessage: "What is a private transfer?",
  },
  privateTransferTitle: {
    id: "daytripPromo.privateTransferTitle",
    description: "Mobile pop up title ",
    defaultMessage: "Private Transfer",
  },
  dayTripMessage: {
    id: "daytripPromo.dayTripMessage",
    description:
      "When tooltip is clicked, the information text shown to the user",
    defaultMessage:
      "Get connected with a local driver that provides door-to-door transport with optional sightseeing stops.",
  },
});
